//// DESKTOP

// Testimonial slider
if ($('.section-testimonials ul.list-testimonial li').length) {

	var slide = $('.section-testimonials ul.list-testimonial');

	slide.slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		dots: true,
		arrows: false,
	});
}

// Post image Slider
if ($('#events-post .section-hero ul.list-image li, #news-post .section-hero ul.list-image li').length) {

	var slide = $('.section-hero ul.list-image');

	slide.slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		dots: true,
		arrows: false,
	});
}

// Holding page smooth scroll
if ($('#holding-page').length) {
	$('#holding-page [href^="#"]').on('click', function (e) {
		e.preventDefault();

		var link = $(this).attr('href').split('#').pop();
		var targetScroll = $('#' + link).offset().top;

		$('html, body').animate({ scrollTop: targetScroll }, 700, 'swing');
	});
}

// Location gallery
$("#location").on("click", ".section-intro-boxes ul li.item-box", function () {
	// Get location box c_id from data attribute
	var locationBoxId = $(this).data("id");

	// Ajax request modal content
	$.ajax({
		url: `api/location-modal-content/${locationBoxId}`
	}).done(function (response) {
		// Populate content on response
		$("#location .section-modal .sub-content").html(response);
		// initialise carousel inside modal
		locationModalCarousel();
		// Show modal
		$("#location .section-modal").addClass('mod-active');
	});
});

// Close location modal on click
$("#location").on("click", ".section-modal .sub-overlay, .section-modal .sub-close", function () {
	$("#location .section-modal").removeClass('mod-active');
});

//// MOBILE

// Mobile main menu dropdown
$('.header-main .btn-mobile').click(() => {
	$('.header-main .btn-mobile').toggleClass('active');
	$('.header-main .sub-main-nav ul.list-nav').toggleClass('active');
});

// Mobile main nav sub list dropdown
$('.header-main ul.list-nav .mod-sub-nav').click(() => {
	$('.header-main ul.list-nav .mod-sub-nav').toggleClass('mod-toggle');
});

// Hide menu on list item click
$('.header-main ul.list-nav li a').click(() => {
	$('.header-main ul.list-nav').removeClass('mod-active');
});

// Mobile secondary menu dropdown
$('.header-main .sub-secondary-header .mod-mobile-toggle .btn-location-mobile-menu').click(() => {
	$('.header-main .sub-secondary-header .mod-mobile-toggle').toggleClass('mod-active');
	$('.header-main .sub-secondary-header ul.list-nav').toggleClass('mod-active');
});

// Grown up stuff dropdown toggle
$('ul.list-sub-page li.mod-mobile').click(() => {
	$('ul.list-sub-page').toggleClass('mod-active');
});


//// OTHER FUNCTIONS

// AJAX POST request, set cookie and if successful close modal
$('.close-modal-button').click(function (e) {
	// Ajax request modal content
	$.ajax({
		url: 'api/cookies',
		method: 'POST',
	}).done(function (response) {
		$(".section-covid-modal, .sub-modal-background").addClass("mod-hidden");
	});
})

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Google Maps
if ($('#map').length) {

	const latLong = { lat: 52.0480135, lng: -0.8294635 };

	// Create a map instance.
	const map = new MelonMap(document.getElementById('map'), {
		zoom: 8,
		center: {
			lat: 52.679402,
			lng: -0.59799
		},
	});

	// TODO: Fix map pins - Nathan pls help

	// Create map array
	const mapPins = {
		'miltonKeynes': {
			'markerImage': 'assets/images/pin-mk.png',
			'size': [90, 92],
			'shape': [46, 95, 37, 90, 36, 82, 38, 76, 35, 63, 26, 58, 23, 53, 24, 49, 17, 48, 12, 42, 9, 34, 12, 27, 17, 22, 24, 21, 27, 20, 39, 16, 40, 10, 45, 11, 48, 10, 52, 13, 49, 15, 59, 16, 63, 18, 70, 16, 77, 17, 82, 22, 84, 28, 84, 35, 79, 39, 72, 42, 74, 50, 69, 55, 61, 59, 55, 76, 58, 83, 57, 90, 53, 93]
		},
		'peterborough': {
			'markerImage': 'assets/images/pin-peterborough.png',
			'size': [75, 108],
			'shape': [36, 107, 29, 105, 26, 99, 27, 91, 33, 86, 34, 80, 26, 68, 13, 52, 7, 39, 8, 29, 14, 20, 20, 14, 28, 11, 41, 7, 52, 8, 61, 17, 65, 29, 66, 40, 59, 56, 53, 65, 42, 76, 42, 87, 48, 92, 47, 98, 45, 103, 41, 107]
		}
	}

	// Marker locations [name, lat, long, zindex, mapPin]
	var locations = [
		['safariMk', latLong.lat, latLong.lng, 2, mapPins.miltonKeynes],
		['safariPlay Peterborough', 52.6072582, -0.2824458, 2, mapPins.peterborough]

	];

	// Foreach location
	locations.forEach((location) => {
		// set title
		const title = location[0];

		// Set map location
		const mapLocation = {
			lat: parseFloat(location[1]),
			lng: parseFloat(location[2])
		}

		// Set marker coords
		map.markerShape.coords = location[4].shape;
		// Set marker image from location
		map.setMarkerImage(location[4].markerImage, location[4].size);

		// Add marker
		map.addMarker(mapLocation, title);
	});


	// Recenter the map based on the pins.
	// map.centerMap();

	// Add a map style
	map.setMapStyle(
		[{ "featureType": "all", "stylers": [{ "saturation": 0 }, { "hue": "#e7ecf0" }] }, { "featureType": "road", "stylers": [{ "saturation": -70 }] }, { "featureType": "transit", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "stylers": [{ "visibility": "simplified" }, { "saturation": -60 }] }],
		{ name: 'Styled Map' }
	);
}

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// GDPR Modal for footer subscribe
	// The form of which to attach the modal to.
	const formSelector = 'form#form-newsletter';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'getfile/8968';
	// Color of the links in the modal
	const linkColour = '#FE7320';
	// Color of the buttons in the modal.
	const buttonTextColour = '#FFF';
	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}

// This automatically adds 'data-sitekey' to the buttons.
// and programmically binds a callback for captcha completion.
// add '.captcha-ignore' as a class on the button to ignore
// the button and disable captcha on that form.
var CaptchaCallback = function () {
	var SITE_KEY = '6LfmkLEUAAAAAKC0R_-reyGDj0p86jfFUNw5ywOw';

	// Don't execute if no site key present.
	if (SITE_KEY === '') {
		return;
	}

	// Get all form elements on page.
	var pageForms = $('form');

	// Ensure the page has a form on it, at least.
	if (pageForms.length <= 0) {
		return;
	}


	pageForms.each(function (formIncId, form) {
		// Grab all of the 'submit' buttons which are attached to the form.
		var submissionButtons = $(form).find('input[type="submit"]:not(.captcha-ignore)');

		if (submissionButtons.length <= 0) {
			return; // Return here is similar to 'continue' within a loop.
		}

		submissionButtons.each(function (btnIncId, button) {
			$(button).attr('data-sitekey', SITE_KEY);

			grecaptcha.render(button, {
				sitekey: SITE_KEY,
				callback: function () {
					$(button).closest('form').submit();
				}
			});
		});
	});
};

// Subscribe footer form processing
$('form#form-newsletter').submit(function (e) {
	e.preventDefault();
	$.ajax({
		method: 'POST',
		url: 'process/subscribe/',
		data: $(this).serialize()
	}).done(function (response) {
		if (response == 'success') {
			// Hide form
			$('form#form-newsletter').hide();
			$('.footer-main .sub-newsletter .sub-thanks').show();
			// Show thanks
		}
		else {
			// Show errors
			$('.footer-main .sub-newsletter .sub-errors').html(response);
		}
	});
});

// Initialise gallery carousel
const locationModalCarousel = function () {
	if ($("#location .section-modal ul.list-modal-images li").length > 0) {
		var slide = $(".section-modal ul.list-modal-images");

		slide.slick({
			slidesToShow: 1,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			dots: false,
			arrows: true,
			draggable: true
		});
	}
};